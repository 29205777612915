<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputAddress" class="form-label">Lý trình</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputAddress"
          placeholder="Nhập lý trình"
          formControlName="address"
          oninput="this.value = this.value.replace(/^0(?=\d)|[^0-9]/g, '');"
          [ngClass]="{'is-invalid': form.get('address')?.invalid || feedbackErrors['addressExist']}"
          (focus)="focusInputAddress()"
          (focusout)="focusoutInputAddress()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('address')?.errors?.['min']">Lý trình nhỏ hơn 0</p>
          <p *ngIf="form.get('address')?.errors?.['required']">Lý trình không được để trống</p>
          <p *ngIf="feedbackErrors['addressExist']">Lý trình đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputName" class="form-label">Tên lý trình</label>
          <p *ngIf="station.isTrainStation" class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputName"
          placeholder="{{ station.isTrainStation ? 'Nhập tên' : '' }}"
          formControlName="name"
          [readonly]="!station.isTrainStation"
          [ngClass]="{'is-invalid': form.get('name')?.invalid || feedbackErrors['nameExist']}"
          (focus)="focusInputName()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('name')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('name')?.errors?.['required']">Tên không được để trống</p>
          <p *ngIf="feedbackErrors['nameExist']">Tên đã tồn tại</p>
        </div>
      </div>
      <ng-container *ngIf="!station.isTrainStation">
        <div class="mb-3">
          <div class="d-inline-flex">
            <p class="form-label">Khu vực</p>
            <p class="mx-1 text-danger">*</p>
          </div>
          <div class="w-100">
            <app-selection-single
              [datasets]="areaDatasets"
              [hasInvalid]="form.get('areaId')?.invalid || feedbackErrors['areaNotFound']"
              [hasSearch]="true"
              [initDataSelected]="initAreaSelected"
              [isGettingDatasets]="isGettingAreas"
              [ngClass]="{'is-invalid': form.get('areaId')?.invalid || feedbackErrors['areaNotFound']}"
              [position]="'top'"
              [searchPlaceholder]="'Nhập tên'"
              (changeSelectionEvent)="onChangeAreaId($event)"
              (searchValueEvent)="getAreaDatasets($event)"
            ></app-selection-single>
            <div class="invalid-feedback">
              <p *ngIf="form.get('areaId')?.errors?.['required']">Chọn một khu vực</p>
              <p *ngIf="feedbackErrors['areaNotFound']">Khu vực không còn tồn tại</p>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="d-inline-flex">
            <p class="form-label">Thiết bị</p>
            <p class="mx-1 text-danger">*</p>
          </div>
          <div class="w-100">
            <app-selection-single
              [datasets]="deviceDatasets"
              [hasInvalid]="
                form.get('deviceId')?.invalid || feedbackErrors['deviceNotFound'] || feedbackErrors['deviceUsed']
              "
              [hasSearch]="true"
              [initDataSelected]="initDeviceSelected"
              [isGettingDatasets]="isGettingDevices"
              [ngClass]="{
                'is-invalid':
                  form.get('deviceId')?.invalid || feedbackErrors['deviceNotFound'] || feedbackErrors['deviceUsed'],
              }"
              [position]="'top'"
              (changeSelectionEvent)="onChangeDeviceId($event)"
              (searchValueEvent)="getDeviceDatasets($event)"
            ></app-selection-single>
            <div class="invalid-feedback">
              <p *ngIf="form.get('deviceId')?.errors?.['required']">Chọn một thiết bị</p>
              <p *ngIf="feedbackErrors['deviceNotFound']">Thiết bị không còn tồn tại</p>
              <p *ngIf="feedbackErrors['deviceUsed']">Thiết bị đã có lý trình</p>
            </div>
          </div>
        </div>
        <div class="mb-3" formGroupName="simulation">
          <div class="d-inline-flex">
            <p class="form-label">Mô phỏng</p>
            <p class="mx-1 text-danger">*</p>
          </div>
          <div class="w-100 d-inline-flex justify-content-between align-items-center my-1">
            <p class="form-label px-2">&bull; Cần chắn đơn</p>
            <div>
              <label class="rcms-switch-brand">
                <input type="checkbox" formControlName="barrierSingle" />
                <span class="rcms-switch__slider rcms-switch__round"></span>
              </label>
            </div>
          </div>
          <div class="w-100 d-inline-flex justify-content-between align-items-center my-1">
            <p class="form-label px-2">&bull; Ngăn đường chẵn</p>
            <div>
              <label class="rcms-switch-brand">
                <input type="checkbox" formControlName="roadBlockLightEvenShow" />
                <span class="rcms-switch__slider rcms-switch__round"></span>
              </label>
            </div>
          </div>
          <div class="w-100 d-inline-flex justify-content-between align-items-center my-1">
            <p class="form-label px-2">&bull; Ngăn đường lẻ</p>
            <div>
              <label class="rcms-switch-brand">
                <input type="checkbox" formControlName="roadBlockLightOddShow" />
                <span class="rcms-switch__slider rcms-switch__round"></span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>
