import {environment} from '../../environments/environment';

export class EnvironmentConstant {
  static readonly API_BACKEND_URL = environment?.apiConfigs?.backend ?? 'http://127.0.0.1:3050/api/backend/v1';

  static readonly API_CACHE_URL = environment?.apiConfigs?.cache ?? 'http://127.0.0.1:3051/api/cache/v1';

  static readonly RABBIT_MQ = {
    BROKER_URL: environment?.rabbitConfigs?.brokerURL ?? 'wss://localhost:15670/ws',
    LOGIN: environment?.rabbitConfigs?.login ?? 'web',
    PASSCODE: environment?.rabbitConfigs?.passcode ?? 'web_pass',
    HOST: environment?.rabbitConfigs?.host ?? 'rcms',
    DEBUG: environment?.rabbitConfigs?.debug ?? false,
  }
}
