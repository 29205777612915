import {inject, Injectable} from '@angular/core';
import {StationSpecificationApi} from '@rcms/apis';
import {
  ICountResponse,
  IFileExportResponse,
  ILoopBackFilters,
  IStationSpecificationReportFileExportRequest,
  IStationSpecificationReportResponse,
} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StationSpecificationService {
  private stationSpecificationApi = inject(StationSpecificationApi);

  getAllStationSpecificationReportsObs(filters: ILoopBackFilters): Observable<IStationSpecificationReportResponse[]> {
    return this.stationSpecificationApi.getAllStationSpecificationReports(filters);
  }

  getCountAllStationSpecificationReportsObs(where: object): Observable<ICountResponse> {
    return this.stationSpecificationApi.getCountAllStationSpecificationReports(where);
  }

  getReportFileObs(requestBody: IStationSpecificationReportFileExportRequest): Observable<IFileExportResponse> {
    return this.stationSpecificationApi.getReportFile(requestBody);
  }
}
