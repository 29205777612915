import {EntityState, EntityStore, getEntityType} from '@datorama/akita';
import {differenceInMilliseconds} from 'date-fns';

export interface IEntityState<TEntity> extends EntityState<TEntity, string> {
  totalItems: number;
}

export class BaseStore<TEntity> extends EntityStore<IEntityState<TEntity>> {
  constructor() {
    super({totalItems: 0});
  }

  override akitaPreUpdateEntity(
    prevEntity: Readonly<getEntityType<IEntityState<TEntity>>>,
    nextEntity: TEntity,
  ): getEntityType<IEntityState<TEntity>> {
    const nextEntityLastUpdate = (nextEntity as never)?.['lastUpdate'] ?? 0;
    const prevEntityLastUpdate = (prevEntity as never)?.['lastUpdate'] ?? 0;
    const diffLastUpdate = differenceInMilliseconds(nextEntityLastUpdate, prevEntityLastUpdate);

    const nextEntityModifiedAt = (nextEntity as never)?.['modifiedAt'] ?? 0;
    const prevEntityModifiedAt = (prevEntity as never)?.['modifiedAt'] ?? 0;
    const diffModifiedAt = differenceInMilliseconds(nextEntityModifiedAt, prevEntityModifiedAt);

    if (diffModifiedAt >= 0 || diffLastUpdate >= 0) {
      return super.akitaPreUpdateEntity(prevEntity, nextEntity);
    }

    return prevEntity;
  }
}
