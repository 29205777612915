export const environment = {
  production: false,
  apiConfigs: {
    backend: 'https://rcms.tfasolutions.net:3040/api/backend/v1',
    cache: 'https://rcms.tfasolutions.net:3041/api/cache/v1',
  },
  rabbitConfigs: {
    brokerURL: 'wss://rcms.tfasolutions.net:15640/ws',
    login: 'web',
    passcode: 'web_pass',
    host: 'dev',
    debug: false,
  },
  dateRelease: '03/04/2025',
  translationHash: '1.1.0_0002-dev',
};
