import {computed, signal} from '@angular/core';
import {IStationResponse, ITimeSelection, TOptional} from '@rcms/types';

export class DataManagementSignal {
  static dataSelected = computed<TOptional<{station: IStationResponse; time: ITimeSelection}>>(() => {
    const stationSelected = DataManagementSignal.stationSelected();
    const timeSelected = DataManagementSignal.timeSelected();

    if (!stationSelected || !timeSelected) {
      return undefined;
    }

    return {station: stationSelected, time: timeSelected};
  });

  static stationSelected = signal<TOptional<IStationResponse>>(undefined);

  static timeSelected = signal<TOptional<ITimeSelection>>(undefined);

  static clear() {
    DataManagementSignal.stationSelected.set(undefined);
    DataManagementSignal.timeSelected.set(undefined);
  }
}
