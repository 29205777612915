import {signal} from '@angular/core';
import {
  IAlarmResponse,
  IAreaResponse,
  ICompanyResponse,
  IDeviceResponse,
  IRoleResponse,
  IStationEventResponse,
  IStationResponse,
  IStreamFileExport,
  ITrainStationResponse,
  IUserResponse,
  TOptional,
} from '@rcms/types';

export class StreamSignal {
  static alarm = signal<TOptional<IAlarmResponse>>(undefined);
  static area = signal<TOptional<IAreaResponse>>(undefined);
  static company = signal<TOptional<ICompanyResponse>>(undefined);
  static device = signal<TOptional<IDeviceResponse>>(undefined);
  static role = signal<TOptional<IRoleResponse>>(undefined);
  static station = signal<TOptional<Partial<IStationResponse>>>(undefined);
  static stationEvent = signal<TOptional<IStationEventResponse>>(undefined);
  static stationEventLogs = signal<TOptional<{stationId: string}>>(undefined);
  static stationEventReportFile = signal<TOptional<IStreamFileExport>>(undefined);
  static stationSpecificationLogs = signal<TOptional<{stationId: string}>>(undefined);
  static stationSpecificationReportFile = signal<TOptional<IStreamFileExport>>(undefined);
  static trainStation = signal<TOptional<ITrainStationResponse>>(undefined);
  static user = signal<TOptional<IUserResponse>>(undefined);

  static clear() {
    StreamSignal.alarm.set(undefined);
    StreamSignal.area.set(undefined);
    StreamSignal.company.set(undefined);
    StreamSignal.device.set(undefined);
    StreamSignal.role.set(undefined);
    StreamSignal.station.set(undefined);
    StreamSignal.stationEvent.set(undefined);
    StreamSignal.stationEventLogs.set(undefined);
    StreamSignal.stationEventReportFile.set(undefined);
    StreamSignal.stationSpecificationLogs.set(undefined);
    StreamSignal.stationSpecificationReportFile.set(undefined);
    StreamSignal.trainStation.set(undefined);
    StreamSignal.user.set(undefined);
  }
}
