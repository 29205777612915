import {Component, inject} from '@angular/core';
import {StationEventService} from '@rcms/services';
import {StreamSignal} from '@rcms/states/signals';
import {IStationEventReportFileExportRequest} from '@rcms/types';
import {BaseExportFileModalComponent} from '../base-export-file-modal.component';

@Component({
  selector: 'app-station-event-report-file-export-modal',
  templateUrl: './station-event-report-file-export-modal.component.html',
  styleUrls: ['./station-event-report-file-export-modal.component.scss'],
})
export class StationEventReportFileExportModalComponent
  extends BaseExportFileModalComponent<IStationEventReportFileExportRequest> {
  private stationEventService = inject(StationEventService);

  constructor() {
    super();
  }

  doRequestObs(queryBody: IStationEventReportFileExportRequest) {
    return this.stationEventService.getReportFileObs(queryBody);
  }

  getStreamSignal() {
    return StreamSignal.stationEventReportFile();
  }
}
