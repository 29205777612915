import {Component, inject, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {ApiConstant, CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {StationAddressPipe} from '@rcms/pipes';
import {AreaService, DeviceService, StationService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ISelection, IStationCreateRequest, IStationResponse, LoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';
import {cloneDeep as _cloneDeep} from 'lodash-es';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-station-create-modal',
  templateUrl: './station-create-modal.component.html',
  styleUrls: ['./station-create-modal.component.scss'],
})
export class StationCreateModalComponent extends BaseCUDModalComponent<IStationResponse, IStationCreateRequest>
  implements OnInit {
  private areaService = inject(AreaService);
  private deviceService = inject(DeviceService);
  private stationService = inject(StationService);

  areaDatasets: ISelection[] = [];
  deviceDatasets: ISelection[] = [];
  formStation!: FormGroup;
  formTrainStation!: FormGroup;
  isGettingAreas = false;
  isGettingDevices = false;
  isTrainStation = true;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.getAreaDatasets();
    this.getDeviceDatasets();
  }

  doRequestObs(requestBody: IStationCreateRequest) {
    return this.stationService.createStationObs(requestBody);
  }

  focusInputAddress() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.ADDRESS_EXIST);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  focusoutInputAddress() {
    if (this.isTrainStation) return;

    const formAddress = this.form.get(CommonConstant.FIELD.ADDRESS);
    const formName = this.form.get(CommonConstant.FIELD.NAME);
    if (formAddress?.value) {
      const name = new StationAddressPipe().transform(formAddress.value);
      formName?.setValue(name);
    } else {
      formName?.setValue('');
    }
  }

  getAreaDatasets(name?: string) {
    this.isGettingAreas = true;
    this.areaDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.areaService.getAllAreasObs(filters.serialize()).subscribe({
      next: (areas) => {
        this.isGettingAreas = false;
        this.areaDatasets = areas.map(area => ({label: area.name, value: area.id}));
      },
      error: () => this.isGettingAreas = false,
    });
  }

  getDeviceDatasets(name?: string) {
    this.isGettingDevices = true;
    this.deviceDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    const whereFilter = name ? {name: ApiUtil.generateLoopbackFilterLike(name)} : {};
    filters.setWhere({...whereFilter, hasStation: false});
    this.deviceService.getAllDevicesObs(filters.serialize()).subscribe({
      next: (devices) => {
        this.isGettingDevices = false;
        this.deviceDatasets = devices.map(device => ({label: device.name, value: device.id}));
      },
      error: () => this.isGettingDevices = false,
    });
  }

  getRequestParams(): IStationCreateRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.STATION);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.STATION);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.STATION_CREATE);
  }

  initForm() {
    this.formStation = this.fb.group({
      [CommonConstant.FIELD.AREA_ID]: ['', [Validators.required]],
      [CommonConstant.FIELD.DEVICE_ID]: ['', [Validators.required]],
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256)]],
      [CommonConstant.FIELD.ADDRESS]: ['', [Validators.min(0), Validators.required]],
      [CommonConstant.FIELD.IS_TRAIN_STATION]: [false],
      [CommonConstant.FIELD.SIMULATION]: this.fb.group({
        [CommonConstant.FIELD.SIMULATION_BARRIER_SINGLE]: [false],
        [CommonConstant.FIELD.SIMULATION_ROAD_BLOCK_LIGHT_EVEN_SHOW]: [false],
        [CommonConstant.FIELD.SIMULATION_ROAD_BLOCK_LIGHT_ODD_SHOW]: [false],
      }),
    });
    this.formTrainStation = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.ADDRESS]: ['', [Validators.min(0), Validators.required]],
      [CommonConstant.FIELD.IS_TRAIN_STATION]: [true],
    });
    this.onChangeIsTrainStation();
  }

  onChangeAreaId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.AREA_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.AREA_NOT_FOUND);
  }

  onChangeDeviceId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.DEVICE_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.DEVICE_NOT_FOUND, ErrorConstant.ERROR_FLAG.DEVICE_USED);
  }

  onChangeIsTrainStation() {
    this.isTrainStation = !this.isTrainStation;
    this.form = this.isTrainStation ? _cloneDeep(this.formTrainStation) : _cloneDeep(this.formStation);
    this.hasChange = false;
    this.clearFeedback();
    this.clearFeedbackErrors();
    this.onFormChangeValues();
  }
}
