import {Injectable} from '@angular/core';
import {EnvironmentConstant, StreamConstant} from '@rcms/constants';
import {StreamSignal} from '@rcms/states/signals';
import {IUserResponse} from '@rcms/types';
import {StreamUtil} from '@rcms/utils';
import {RxStomp, RxStompConfig} from '@stomp/rx-stomp';
import {Message, StompHeaders} from '@stomp/stompjs';
import {map, Observable, Subscription} from 'rxjs';
import {v4 as uuidV4} from 'uuid';

@Injectable({providedIn: 'root'})
export class StreamService extends RxStomp {
  private stompHeaders: StompHeaders = {
    'exclusive': 'true',
    'x-queue-name': `web.rcms.${uuidV4()}`,
  };
  private subscriptionList: Subscription[] = [];

  constructor() {
    super();
  }

  clearSubscription() {
    this.subscriptionList.forEach(subscription => subscription.unsubscribe());
    this.subscriptionList = [];
  }

  makeSubscription(data: IUserResponse) {
    if (data.company.isSystemOwner) {
      const topicAreaAdmin = StreamUtil.getTopicWatchFromArea(StreamConstant.ID.WILDCARD);
      this.addSubscription(this.watchMessageObs(topicAreaAdmin).subscribe((message) => this.handleMessage(message)));

      const topicCompanyAdmin = StreamUtil.getTopicWatchFromCompany(StreamConstant.ID.WILDCARD);
      this.addSubscription(this.watchMessageObs(topicCompanyAdmin).subscribe((message) => this.handleMessage(message)));
    } else {
      const topicAreaAll = StreamUtil.getTopicWatchFromArea(StreamConstant.ID.ALL);
      this.addSubscription(this.watchMessageObs(topicAreaAll).subscribe((message) => this.handleMessage(message)));

      data.areas.forEach(area => {
        const topicArea = StreamUtil.getTopicWatchFromArea(area.id);
        this.addSubscription(this.watchMessageObs(topicArea).subscribe((message) => this.handleMessage(message)));
      });

      const topicCompany = StreamUtil.getTopicWatchFromCompany(data.companyId);
      this.addSubscription(this.watchMessageObs(topicCompany).subscribe((message) => this.handleMessage(message)));
    }
  }

  private addSubscription(subscription: Subscription) {
    this.subscriptionList.push(subscription);
  }

  private handleMessage(message: Record<string, never> | null) {
    if (message) {
      const {
        area,
        alarm,
        company,
        device,
        role,
        station,
        stationEvent,
        stationEventLogs,
        stationEventLogsFile,
        stationSpecificationLogs,
        stationSpecificationLogsFile,
        trainStation,
        user,
      } = message;
      if (alarm) {
        StreamSignal.alarm.set(alarm);
      }
      if (area) {
        StreamSignal.area.set(area);
      }
      if (company) {
        StreamSignal.company.set(company);
      }
      if (device) {
        StreamSignal.device.set(device);
      }
      if (role) {
        StreamSignal.role.set(role);
      }
      if (station) {
        StreamSignal.station.set(station);
      }
      if (stationEvent) {
        StreamSignal.stationEvent.set(stationEvent);
      }
      if (stationEventLogs) {
        StreamSignal.stationEventLogs.set(stationEventLogs);
      }
      if (stationEventLogsFile) {
        StreamSignal.stationEventReportFile.set(stationEventLogsFile);
      }
      if (stationSpecificationLogs) {
        StreamSignal.stationSpecificationLogs.set(stationSpecificationLogs);
      }
      if (stationSpecificationLogsFile) {
        StreamSignal.stationSpecificationReportFile.set(stationSpecificationLogsFile);
      }
      if (trainStation) {
        StreamSignal.trainStation.set(trainStation);
      }
      if (user) {
        StreamSignal.user.set(user);
      }
    }
  }

  private watchMessageObs(topic: string): Observable<Record<string, never> | null> {
    return this.watch(topic, this.stompHeaders).pipe(map((message: Message) => {
      try {
        return JSON.parse(message.body);
      } catch (error) {
        return null;
      }
    }));
  }
}

export const streamConfig: RxStompConfig = {
  // Which server?
  brokerURL: EnvironmentConstant.RABBIT_MQ.BROKER_URL,

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: EnvironmentConstant.RABBIT_MQ.LOGIN,
    passcode: EnvironmentConstant.RABBIT_MQ.PASSCODE,
    host: EnvironmentConstant.RABBIT_MQ.HOST,
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milliseconds)
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string) => {
    if (EnvironmentConstant.RABBIT_MQ.DEBUG) {
      console.log(new Date(), msg);
    }
  },
};

export function streamServiceFactory() {
  const rxStomp = new StreamService();
  rxStomp.configure(streamConfig);
  rxStomp.activate();
  return rxStomp;
}

