import {Component, inject} from '@angular/core';
import {StationSpecificationService} from '@rcms/services';
import {StreamSignal} from '@rcms/states/signals';
import {IStationSpecificationReportFileExportRequest} from '@rcms/types';
import {BaseExportFileModalComponent} from '../base-export-file-modal.component';

@Component({
  selector: 'app-station-specification-report-file-export-modal',
  templateUrl: './station-specification-report-file-export-modal.component.html',
  styleUrls: ['./station-specification-report-file-export-modal.component.scss'],
})
export class StationSpecificationReportFileExportModalComponent
  extends BaseExportFileModalComponent<IStationSpecificationReportFileExportRequest> {
  private stationSpecificationService = inject(StationSpecificationService);

  constructor() {
    super();
  }

  doRequestObs(queryBody: IStationSpecificationReportFileExportRequest) {
    return this.stationSpecificationService.getReportFileObs(queryBody);
  }

  getStreamSignal() {
    return StreamSignal.stationSpecificationReportFile();
  }
}
