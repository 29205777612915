export class ModalConstant {
  static readonly ENTITY = {
    ALARM: 'Cảnh báo trở ngại',
    AREA: 'Khu vực',
    COMPANY: 'Đơn vị',
    DEVICE: 'Thiết bị',
    PASSWORD: 'Mật khẩu',
    STATION: 'Lý Trình',
    STATION_EVENT: 'Sự kiện',
    TRAIN_STATION: 'Nhà ga',
    USER: 'Người dùng',
  } as const;

  static readonly NAME = {
    ALARM_CREATE: 'alarmCreate',
    ALARM_DELETE: 'alarmDelete',
    ALARM_UPDATE: 'alarmUpdate',
    AREA_CREATE: 'areaCreate',
    AREA_DELETE: 'areaDelete',
    AREA_UPDATE: 'areaUpdate',
    COMPANY_CREATE: 'companyCreate',
    COMPANY_DELETE: 'companyDelete',
    COMPANY_UPDATE: 'companyUpdate',
    DEVICE_DELETE: 'deviceDelete',
    DEVICE_UPDATE: 'deviceUpdate',
    ROLE_DETAIL: 'roleDetail',
    STATION_CREATE: 'stationCreate',
    STATION_EVENT_CREATE: 'stationEventCreate',
    STATION_EVENT_DELETE: 'stationEventDelete',
    STATION_EVENT_REPORT_FILE_EXPORT: 'stationEventReportFileExport',
    STATION_EVENT_UPDATE: 'stationEventUpdate',
    STATION_UPDATE: 'stationUpdate',
    STATION_SPECIFICATION_REPORT_FILE_EXPORT: 'stationSpecificationReportFileExport',
    TRAIN_STATION_CREATE: 'trainStationCreate',
    TRAIN_STATION_DELETE: 'trainStationDelete',
    TRAIN_STATION_UPDATE: 'trainStationUpdate',
    USER_CREATE: 'userCreate',
    USER_DELETE: 'userDelete',
    USER_DETAIL: 'userDetail',
    USER_UPDATE: 'userUpdate',
    USER_UPDATE_MY_PASSWORD: 'userUpdateMyPassword',
    USER_UPDATE_MY_PROFILE: 'userUpdateMyProfile',
  } as const;
}
