import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';
import {IStationResponse} from './station.type';

export interface IDeviceResponse extends ICreatedAndModifiedResponse {
  id: string;
  hasStation: boolean;
  interaction: IEntityInteraction;
  lastUpdate: string;
  name: string;
  online: boolean;
  settings: object;
  specifications: {
    voltages?: {
      sensor1: number;
      sensor2: number;
      sensor3: number;
      sensor4: number;
      sensor5: number;
      sensor6: number;
      sensor7: number;
      supplier: number;
    };
  };
  station: IStationResponse | null;
}

export interface IDeviceUpdateRequest {
  name?: string;
}
