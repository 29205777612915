import {IAreaResponse} from './area.type';
import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';
import {ICompanyResponse} from './company.type';
import {IDeviceResponse} from './device.type';
import {IStationEventResponse} from './station-event.type';
import {IStationStationEventResponse} from './station-station-event.type';

export interface IStationResponse extends ICreatedAndModifiedResponse {
  id: string;
  address: number;
  areaId: string;
  deviceId: string | null;
  interaction: IEntityInteraction;
  isTrainStation: boolean;
  lastUpdate: string;
  name: string;
  obstacles: IStationObstacle[];
  simulation: IStationSimulation;
  area: IAreaResponse | null;
  companyMaintenance: ICompanyResponse | null;
  companyObserver: ICompanyResponse | null;
  device: IDeviceResponse | null;
}

export interface IStationChangeDemoRequest {
  playTimes: number;
}

export interface IStationCreateRequest {
  areaId: string | null;
  deviceId: string | null;
  name?: string;
  address: number;
  isTrainStation: boolean;
  simulation: {
    barrierSingle: boolean;
    roadBlockLightEvenShow: boolean;
    roadBlockLightOddShow: boolean;
  }
}

export interface IStationObstacle {
  stationEvent: IStationEventResponse;
  stationStationEvent: IStationStationEventResponse;
}

export interface IStationSimulation {
  acError: boolean;
  barrier1: boolean;
  barrier1Error: boolean;
  barrier2: boolean;
  barrier2Error: boolean;
  barrier3: boolean;
  barrier3Error: boolean;
  barrier4: boolean;
  barrier4Error: boolean;
  barrierSingle: boolean;
  dcError: boolean;
  incandescentError: boolean;
  obstacleButton: boolean;
  obstacleLight: boolean;
  pole1Light1: boolean;
  pole1Light1Error: boolean;
  pole1Light2: boolean;
  pole1Light2Error: boolean;
  pole1Sound1Error: boolean;
  pole2Light1: boolean;
  pole2Light1Error: boolean;
  pole2Light2: boolean;
  pole2Light2Error: boolean;
  pole2Sound1Error: boolean;
  roadBlockButton: boolean;
  roadBlockLightEven: boolean;
  roadBlockLightEvenError: boolean;
  roadBlockLightEvenShow: boolean;
  roadBlockLightOdd: boolean;
  roadBlockLightOddError: boolean;
  roadBlockLightOddShow: boolean;
  sensor1Error: boolean;
  sensor2Error: boolean;
  sensor3Error: boolean;
  sensor4Error: boolean;
  sensor5Error: boolean;
  sensor6Error: boolean;
  sensor7Error: boolean;
  trainApproachEven: boolean;
  trainApproachOdd: boolean;
  trainApproachZone1: boolean;
  trainApproachZone2: boolean;
}

export interface IStationUpdateRequest {
  areaId?: string;
  deviceId?: string | null;
  name?: string;
  address?: number;
  simulation?: {
    barrierSingle?: boolean;
    roadBlockLightEvenShow?: boolean;
    roadBlockLightOddShow?: boolean;
  }
}
