import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {
  ICountResponse,
  IFileExportResponse,
  ILoopBackFilters,
  IStationSpecificationReportFileExportRequest,
  IStationSpecificationReportResponse,
} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class StationSpecificationApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.STATION_SPECIFICATIONS;
  private prefixReport = 'reports';
  private prefixReportCount = 'reports/count';
  private prefixReportFile = 'reports/file';

  getAllStationSpecificationReports(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixReport}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<IStationSpecificationReportResponse[]>(url, {params: queryParams});
  }

  getCountAllStationSpecificationReports(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixReportCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  getReportFile(requestBody: IStationSpecificationReportFileExportRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixReportFile}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery(requestBody);

    return this.httpClient.get<IFileExportResponse>(url, {params: queryParams});
  }
}
