export class ApiConstant {
  static readonly API_ENTITY = {
    ALARMS: 'alarms',
    AREAS: 'areas',
    COMPANIES: 'companies',
    DEVICE: 'devices',
    FILES: 'files',
    ROLES: 'roles',
    STATIONS: 'stations',
    STATION_EVENTS: 'station-events',
    STATION_SPECIFICATIONS: 'station-specifications',
    TRAIN_STATIONS: 'train-stations',
    USERS: 'users',
  } as const;

  static readonly LOOPBACK_FILTER = {
    LIMIT_DEFAULT: 10,
    LIMIT_MAX_SIZE: 100,
    ORDER_ASC: 'ASC',
    ORDER_DESC: 'DESC',
    SKIP_DEFAULT: 0,
  };
}
