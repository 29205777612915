import {IAlarmResponse} from './alarm.type';
import {ICreatedAndModifiedResponse} from './common.type';
import {TFileDownloadType} from './file.type';

export interface IStationEventReportResponse extends ICreatedAndModifiedResponse {
  id: string;
  alarmLevel: number;
  code: number;
  name: string;
  note: string;
  stationId: string;
  alarm: IAlarmResponse | null;
}

export interface IStationEventReportFileExportRequest {
  fileExtension: TFileDownloadType;
  timezone: string;
  where: object;
}
