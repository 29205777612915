import {FileConstant} from '@rcms/constants';
import {TObjectValues} from './common.type';

export interface IFileStorageDownloadRequest {
  accessToken: string;
  fileStorageEncoded: string;
  thumbnailSize?: IFileStorageImageThumbnailSize;
}

export interface IFileStorageImageThumbnailSize {
  height?: number;
  width?: number;
}

export interface IFileStorageResponse {
  encoded: string;
  originalName: string | null;
}

export interface IFileStorageUploadRequest {
  fileStorageEncoded: string;
}

export interface IFileStorageUploadResponse {
  originalName: string;
  mimeType: string;
  size: number;
}

export interface IFileStorageUploadTokenRequest {
  companyId: string;
  entity: TFileUploadEntity;
  entityId: string;
  fieldName: TFileUploadField;
}

export interface IFileStorageUploadTokenResponse {
  accessToken: string;
  fileStorage: IFileStorageResponse;
}

export interface IFileExportResponse {
  fileStorage: IFileStorageResponse;
  topicResponse: string;
  session: string;
}

export type TFileDownloadStatus = TObjectValues<typeof FileConstant.DOWNLOAD_FILE_STATUS>;
export type TFileDownloadType = TObjectValues<typeof FileConstant.DOWNLOAD_FILE_TYPE>;
export type TFileUploadAudioType = TObjectValues<typeof FileConstant.UPLOAD_AUDIO_TYPE>;
export type TFileUploadEntity = TObjectValues<typeof FileConstant.UPLOAD_ENTITY>;
export type TFileUploadField = TObjectValues<typeof FileConstant.UPLOAD_FIELD>;
export type TFileUploadImageType = TObjectValues<typeof FileConstant.UPLOAD_IMAGE_TYPE>;
