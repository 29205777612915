import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {AkitaNgRouterStoreModule} from '@datorama/akita-ng-router-store';
import {HttpErrorInterceptor, HttpHeaderInterceptor} from '@rcms/interceptors';
import {StreamService, streamServiceFactory} from '@rcms/services';
import {TreeviewModule} from 'ngx-treeview2';
import {AppComponent} from './app.component';
import {appRoutes} from './app.routes';
import {LoadingComponent} from './view/loading/loading.component';

@NgModule({
  declarations: [AppComponent, LoadingComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {paramsInheritanceStrategy: 'always'}),
    AkitaNgRouterStoreModule,
    TreeviewModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true},
    {provide: StreamService, useFactory: streamServiceFactory},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
