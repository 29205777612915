import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {
  ICountResponse,
  ILoopBackFilters,
  IStationChangeDemoRequest,
  IStationCreateRequest,
  IStationResponse,
  IStationUpdateRequest,
} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class StationApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.STATIONS;
  private prefixCount = 'count';

  changeStationDemo(body: IStationChangeDemoRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/demo`);

    return this.httpClient.patch<void>(url, body);
  }

  createStation(stationBody: IStationCreateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);

    return this.httpClient.post<IStationResponse>(url, stationBody);
  }

  getAllStations(filtersBody: ILoopBackFilters, simulated = false) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody, simulated});

    return this.httpClient.get<IStationResponse[]>(url, {params: queryParams});
  }

  getCountAllStations(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  updateStationById(stationId: string, requestBody: IStationUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${stationId}`);

    return this.httpClient.patch<IStationResponse>(url, requestBody);
  }
}
