import {inject, Injectable} from '@angular/core';
import {StationApi} from '@rcms/apis';
import {
  ICountResponse,
  ILoopBackFilters,
  IStationChangeDemoRequest,
  IStationCreateRequest,
  IStationResponse,
  IStationUpdateRequest,
} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StationService {
  private stationApi = inject(StationApi);

  changeStationDemoObs(requestBody: IStationChangeDemoRequest): Observable<void> {
    return this.stationApi.changeStationDemo(requestBody);
  }

  createStationObs(requestBody: IStationCreateRequest): Observable<IStationResponse> {
    return this.stationApi.createStation(requestBody);
  }

  getAllStationsObs(filters: ILoopBackFilters, simulated = false): Observable<IStationResponse[]> {
    return this.stationApi.getAllStations(filters, simulated);
  }

  getCountAllStationsObs(where: object): Observable<ICountResponse> {
    return this.stationApi.getCountAllStations(where);
  }

  updateStationByIdObs(stationId: string, requestBody: IStationUpdateRequest): Observable<IStationResponse> {
    return this.stationApi.updateStationById(stationId, requestBody);
  }
}
