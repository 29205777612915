import {ICreatedAndModifiedResponse} from './common.type';
import {TFileDownloadType} from './file.type';

export interface IStationSpecificationReportResponse extends ICreatedAndModifiedResponse {
  id: string;
  plc: {
    diA?: string;
    diB?: string;
    mrI?: string;
    dqA?: string;
    dqB?: string;
    mrO?: string;
  };
  pulses: {
    sensor1?: number;
    sensor2?: number;
    sensor3?: number;
    sensor4?: number;
    sensor5?: number;
    sensor6?: number;
    sensor7?: number;
  };
  stationId: string;
  voltages: {
    sensor1?: number;
    sensor2?: number;
    sensor3?: number;
    sensor4?: number;
    sensor5?: number;
    sensor6?: number;
    sensor7?: number;
    supplier?: number;
  };
}

export interface IStationSpecificationReportFileExportRequest {
  fileExtension: TFileDownloadType;
  timezone: string;
  where: object;
}
