import {Injectable} from '@angular/core';
import {resetStores} from '@datorama/akita';
import {LocalStorage} from '@rcms/states/local';
import {DataManagementSignal, GlobalSignal, PermissionSignal, StreamSignal} from '@rcms/states/signals';

@Injectable({providedIn: 'root'})
export class StateService {
  static clearAll() {
    StateService.clearLocalStorageState();
    StateService.clearSignalState();
    StateService.clearStoreState();
  }

  static clearLocalStorageState() {
    LocalStorage.clear();
  }

  static clearSignalState() {
    DataManagementSignal.clear();
    GlobalSignal.clear();
    PermissionSignal.clear();
    StreamSignal.clear();
  }

  static clearStoreState() {
    resetStores();
  }
}
