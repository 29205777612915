import {ComponentDecorator} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {IModalComponent, TModalName} from '@rcms/types/modal.type';
import {
  AlarmCreateModalComponent,
  AlarmDeleteModalComponent,
  AlarmUpdateModalComponent,
  AreaCreateModalComponent,
  AreaDeleteModalComponent,
  AreaUpdateModalComponent,
  BaseModalComponent,
  CompanyCreateModalComponent,
  CompanyDeleteModalComponent,
  CompanyUpdateModalComponent,
  DeviceDeleteModalComponent,
  DeviceUpdateModalComponent,
  RoleDetailModalComponent,
  StationCreateModalComponent,
  StationEventCreateModalComponent,
  StationEventDeleteModalComponent,
  StationEventReportFileExportModalComponent,
  StationEventUpdateModalComponent,
  StationSpecificationReportFileExportModalComponent,
  StationUpdateModalComponent,
  TrainStationCreateModalComponent,
  TrainStationDeleteModalComponent,
  TrainStationUpdateModalComponent,
  UserCreateModalComponent,
  UserDeleteModalComponent,
  UserDetailModalComponent,
  UserUpdateModalComponent,
  UserUpdateMyPasswordModalComponent,
  UserUpdateMyProfileModalComponent,
} from '@rcms/view/home/modal';

const modalComponents: Record<TModalName, IModalComponent<typeof BaseModalComponent<unknown>>> = {
  [ModalConstant.NAME.ALARM_CREATE]: {title: 'Tạo mới cảnh báo trở ngại', component: AlarmCreateModalComponent},
  [ModalConstant.NAME.ALARM_DELETE]: {title: 'Xoá cảnh báo trở ngại', component: AlarmDeleteModalComponent},
  [ModalConstant.NAME.ALARM_UPDATE]: {title: 'Chỉnh sửa cảnh báo trở ngại', component: AlarmUpdateModalComponent},
  [ModalConstant.NAME.AREA_CREATE]: {title: 'Tạo mới khu vực', component: AreaCreateModalComponent},
  [ModalConstant.NAME.AREA_DELETE]: {title: 'Xoá khu vực', component: AreaDeleteModalComponent},
  [ModalConstant.NAME.AREA_UPDATE]: {title: 'Chỉnh sửa khu vực', component: AreaUpdateModalComponent},
  [ModalConstant.NAME.COMPANY_CREATE]: {title: 'Tạo mới đơn vị', component: CompanyCreateModalComponent},
  [ModalConstant.NAME.COMPANY_DELETE]: {title: 'Xoá đơn vị', component: CompanyDeleteModalComponent},
  [ModalConstant.NAME.COMPANY_UPDATE]: {title: 'Chỉnh sửa đơn vị', component: CompanyUpdateModalComponent},
  [ModalConstant.NAME.DEVICE_DELETE]: {title: 'Xoá thiết bị', component: DeviceDeleteModalComponent},
  [ModalConstant.NAME.DEVICE_UPDATE]: {title: 'Chỉnh sửa thiết bị', component: DeviceUpdateModalComponent},
  [ModalConstant.NAME.ROLE_DETAIL]: {title: 'Chi tiết Vai trò', component: RoleDetailModalComponent},
  [ModalConstant.NAME.STATION_CREATE]: {title: 'Tạo mới lý trình', component: StationCreateModalComponent},
  [ModalConstant.NAME.STATION_EVENT_CREATE]: {title: 'Tạo mới sự kiện', component: StationEventCreateModalComponent},
  [ModalConstant.NAME.STATION_EVENT_DELETE]: {title: 'Xoá sự kiện', component: StationEventDeleteModalComponent},
  [ModalConstant.NAME.STATION_EVENT_REPORT_FILE_EXPORT]: {
    title: 'Xuất báo cáo sự kiện',
    component: StationEventReportFileExportModalComponent,
  },
  [ModalConstant.NAME.STATION_EVENT_UPDATE]: {title: 'Chỉnh sửa sự kiện', component: StationEventUpdateModalComponent},
  [ModalConstant.NAME.STATION_SPECIFICATION_REPORT_FILE_EXPORT]: {
    title: 'Xuất báo cáo thông số kỹ thuật',
    component: StationSpecificationReportFileExportModalComponent,
  },
  [ModalConstant.NAME.STATION_UPDATE]: {title: 'Chỉnh sửa lý trình', component: StationUpdateModalComponent},
  [ModalConstant.NAME.TRAIN_STATION_CREATE]: {title: 'Tạo mới nhà ga', component: TrainStationCreateModalComponent},
  [ModalConstant.NAME.TRAIN_STATION_DELETE]: {title: 'Xoá nhà ga', component: TrainStationDeleteModalComponent},
  [ModalConstant.NAME.TRAIN_STATION_UPDATE]: {title: 'Chỉnh sửa nhà ga', component: TrainStationUpdateModalComponent},
  [ModalConstant.NAME.USER_CREATE]: {title: 'Tạo mới người dùng', component: UserCreateModalComponent},
  [ModalConstant.NAME.USER_DELETE]: {title: 'Xoá người dùng', component: UserDeleteModalComponent},
  [ModalConstant.NAME.USER_DETAIL]: {title: 'Chi tiết người dùng', component: UserDetailModalComponent},
  [ModalConstant.NAME.USER_UPDATE]: {title: 'Chỉnh sửa người dùng', component: UserUpdateModalComponent},
  [ModalConstant.NAME.USER_UPDATE_MY_PASSWORD]: {
    title: 'Cập nhật mật khẩu',
    component: UserUpdateMyPasswordModalComponent,
  },
  [ModalConstant.NAME.USER_UPDATE_MY_PROFILE]: {
    title: 'Cập nhật thông tin',
    component: UserUpdateMyProfileModalComponent,
  },
};

export class ModalUtil {
  static getModalComponentByName(modalName: TModalName): IModalComponent<ComponentDecorator> {
    return modalComponents[modalName] as never;
  }
}
