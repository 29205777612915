import {HttpParams} from '@angular/common/http';
import {ApiConstant, EnvironmentConstant} from '@rcms/constants';
import {CommonUtil} from './common.util';

export class ApiUtil {
  static convert2HttpParamsWithQuery(obj: unknown): HttpParams {
    return new HttpParams().set(
      'query',
      JSON.stringify(obj, (key, value) => CommonUtil.jsonReplacer(key, value)),
    );
  }

  static generateBackendUrlFromPath(path: string) {
    return `${EnvironmentConstant.API_BACKEND_URL}/${path}`;
  }

  static generateCachedUrlFromPath(path: string) {
    return `${EnvironmentConstant.API_CACHE_URL}/${path}`;
  }

  static generateLoopbackFilterLike(value: string, insensitive = false) {
    return {like: `%${value}%`, options: insensitive ? 'i' : undefined};
  }

  static getOrderASC(field: string) {
    return `${field} ${ApiConstant.LOOPBACK_FILTER.ORDER_ASC}`;
  }

  static getOrderDESC(field: string) {
    return `${field} ${ApiConstant.LOOPBACK_FILTER.ORDER_DESC}`;
  }
}
