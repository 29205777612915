import {Component, effect, inject, OnInit} from '@angular/core';
import {FileConstant} from '@rcms/constants';
import {FileService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {
  IFileExportResponse,
  IFileStorageDownloadRequest,
  IFileStorageResponse,
  IStreamFileExport,
  TFileDownloadStatus,
  TOptional,
} from '@rcms/types';
import {saveAs} from 'file-saver';
import {isEmpty as _isEmpty} from 'lodash-es';
import {Observable} from 'rxjs';
import {BaseModalComponent} from './base-modal.component';

@Component({selector: 'app-base-export-file-modal', template: ''})
export abstract class BaseExportFileModalComponent<TExportFileRequest> extends BaseModalComponent<TExportFileRequest>
  implements OnInit {
  private fileService = inject(FileService);

  fileName = '';
  fileStorage!: IFileStorageResponse;
  session = '';

  message = 'Yêu cầu đang được xử lý, vui lòng chờ trong giây lát';
  status: TFileDownloadStatus = FileConstant.DOWNLOAD_FILE_STATUS.NONE;

  protected constructor() {
    super();
    effect(() => {
      const streamReportFile = this.getStreamSignal();
      if (this.session === streamReportFile?.session) {
        this.handleStreamDownloadFile(streamReportFile);
      }
    });
  }

  ngOnInit() {
    this.status = FileConstant.DOWNLOAD_FILE_STATUS.PROCESSING;
    this.handleRequest();
  }

  abstract doRequestObs(requestParams: TExportFileRequest): Observable<IFileExportResponse>;

  abstract getStreamSignal(): TOptional<IStreamFileExport>;

  handleError() {
    this.message = 'Đã có lỗi xảy ra, vui lòng thử lại sau';
    this.status = FileConstant.DOWNLOAD_FILE_STATUS.ERROR;
  }

  handleRequest() {
    this.status = FileConstant.DOWNLOAD_FILE_STATUS.PROCESSING;
    this.doRequestObs(this.data).subscribe({
      next: (response: IFileExportResponse) => this.handleRequestSuccess(response),
      error: () => this.handleError(),
    });
  }

  handleRequestSuccess(response: IFileExportResponse) {
    this.message = 'Đang xử lý báo cáo, vui lòng chờ trong giây lát';
    this.session = response.session;
    this.fileStorage = response.fileStorage;
    this.fileName = response.fileStorage.originalName?.replaceAll(' ', '_') ?? 'Báo cáo';
  }

  handleStreamDownloadFile(streamReportFile: IStreamFileExport) {
    if (!_isEmpty(streamReportFile.error)) {
      this.handleError();
    } else {
      this.message = 'Đang tải báo cáo, vui lòng chờ trong giây lát';
      const fileStorageDownloadBody: IFileStorageDownloadRequest = {
        fileStorageEncoded: this.fileStorage.encoded,
        accessToken: GlobalSignal.token(),
      };
      this.fileService.downloadFileObs(this.fileName, fileStorageDownloadBody).subscribe(data => {
        if (data) {
          try {
            saveAs(data, this.fileName);
            this.status = FileConstant.DOWNLOAD_FILE_STATUS.SUCCESS;
            this.message = 'Tải báo cáo thành công!';
          } catch (error) {
            this.handleError();
          }
        }
      });
    }
  }
}
