import {inject, Injectable} from '@angular/core';
import {StationEventApi} from '@rcms/apis';
import {
  ICountResponse,
  IFileExportResponse,
  ILoopBackFilters,
  IStationEventCreateRequest,
  IStationEventReportFileExportRequest,
  IStationEventReportResponse,
  IStationEventResponse,
  IStationEventUpdateRequest,
} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StationEventService {
  private stationEventApi = inject(StationEventApi);

  createStationEventObs(requestBody: IStationEventCreateRequest): Observable<IStationEventResponse> {
    return this.stationEventApi.createStationEvent(requestBody);
  }

  deleteStationEventByIdObs(stationEventId: string): Observable<void> {
    return this.stationEventApi.deleteStationEventById(stationEventId);
  }

  getAllStationEventReportsObs(filters: ILoopBackFilters): Observable<IStationEventReportResponse[]> {
    return this.stationEventApi.getAllStationEventReports(filters);
  }

  getAllStationEventsObs(filters: ILoopBackFilters): Observable<IStationEventResponse[]> {
    return this.stationEventApi.getAllStationEvents(filters);
  }

  getCountAllStationEventReportsObs(where: object): Observable<ICountResponse> {
    return this.stationEventApi.getCountAllStationEventReports(where);
  }

  getCountAllStationEventsObs(where: object): Observable<ICountResponse> {
    return this.stationEventApi.getCountAllStationEvents(where);
  }

  getReportFileObs(requestBody: IStationEventReportFileExportRequest): Observable<IFileExportResponse> {
    return this.stationEventApi.getReportFile(requestBody);
  }

  updateStationEventByIdObs(
    stationEventId: string,
    requestBody: IStationEventUpdateRequest,
  ): Observable<IStationEventResponse> {
    return this.stationEventApi.updateStationEventById(stationEventId, requestBody);
  }
}
