import {StreamConstant} from '@rcms/constants';

export class StreamUtil {
  static getTopicWatchFromArea(areaId: string): string {
    return `${StreamConstant.TOPIC_PREFIX.FROM_AREA}.${areaId}.stream.${StreamConstant.ID.WILDCARD}`;
  }

  static getTopicWatchFromCompany(companyId: string): string {
    return `${StreamConstant.TOPIC_PREFIX.FROM_COMPANY}.${companyId}.stream.${StreamConstant.ID.WILDCARD}`;
  }
}
