import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {
  ICountResponse,
  IFileExportResponse,
  ILoopBackFilters,
  IStationEventCreateRequest,
  IStationEventReportFileExportRequest,
  IStationEventReportResponse,
  IStationEventResponse,
  IStationEventUpdateRequest,
} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class StationEventApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.STATION_EVENTS;
  private prefixCount = 'count';
  private prefixReport = 'reports';
  private prefixReportCount = 'reports/count';
  private prefixReportFile = 'reports/file';

  createStationEvent(requestBody: IStationEventCreateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);

    return this.httpClient.post<IStationEventResponse>(url, requestBody);
  }

  deleteStationEventById(stationEventId: string) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${stationEventId}`);

    return this.httpClient.delete<void>(url);
  }

  getAllStationEventReports(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixReport}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<IStationEventReportResponse[]>(url, {params: queryParams});
  }

  getAllStationEvents(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<IStationEventResponse[]>(url, {params: queryParams});
  }

  getCountAllStationEventReports(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixReportCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  getCountAllStationEvents(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  getReportFile(requestBody: IStationEventReportFileExportRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixReportFile}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery(requestBody);

    return this.httpClient.get<IFileExportResponse>(url, {params: queryParams});
  }

  updateStationEventById(stationEventId: string, requestBody: IStationEventUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${stationEventId}`);

    return this.httpClient.patch<IStationEventResponse>(url, requestBody);
  }
}
